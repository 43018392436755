import React, { Fragment, memo } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "common/theme/webAppCreative";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/WebAppCreative/webAppCreative.style";
import Footer from "containers/WebAppCreative/Footer";
import Navbar from "containers/WebAppCreative/Navbar";
import { DrawerProvider } from "common/contexts/DrawerContext";
import Sticky from "react-stickynode";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import { get } from "lodash";
import Seo from "components/seo";
import { ResetCSS } from "common/assets/css/style";

function Layout({
  title = "",
  description = "",
  children,
  showItems = "/blog",
}) {
  const { site, allStrapiHomePage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          supportedLanguages
          siteUrl
        }
      }
      allStrapiHomePage {
        nodes {
          locale
          seo {
            author
            description
            title
            shareImage {
              alternativeText
              url
            }
            keyworks {
              keyword
              id
            }
          }
        }
      }
    }
  `);
  const { language: lang } = useI18next();
  const cmsData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo
          title={title}
          description={description}
          supportedLanguages={get(site, "siteMetadata.supportedLanguages", [])}
          host={site?.siteMetadata?.siteUrl}
          ogImage={get(cmsData, "seo.shareImage.url", "")}
          keywords={get(cmsData, "seo.keyworks", [])}
          author={get(cmsData, "seo.author", "")}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar
                lang={lang}
                supportedLanguages={get(
                  site,
                  "siteMetadata.supportedLanguages",
                  []
                )}
                showItems={showItems}
              />
            </DrawerProvider>
          </Sticky>
          {children}
          <Footer lang={lang} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}
export default memo(Layout);
