import styled from "styled-components";

export const PostWrapper = styled.div`
  width: 100%;
  padding-top: 120px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 8fr 3fr;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

export const GridItem = styled.div`
  overflow: hidden;
  .content-data {
    margin-top: 50px;
    margin-bottom: 100px;
    p,
    li {
      font-size: 16px;
      line-height: 2.2;
      text-align: justify;
    }
    img {
      margin-top: 20px;
      margin-bottom: 20px;
      object-fit: cover;
      width: 100%;
    }
    ul {
      list-style-position: inside;
    }
    li {
      list-style-type: disc;
      font-family: system-ui, sans-serif;
      margin-left: 30px;
    }
  }
  .heading-right {
    border-bottom: solid 2px #2c6fee;
    padding-bottom: 14.5px;
    font-size: 20px;
    color: rgb(51, 51, 51);
    font-weight: 600;
  }
`;
