import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import Container from "common/components/UI/Container";
import { Grid, GridItem, PostWrapper } from "./post.styles";
import parse from "html-react-parser";
import { Heading } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Post({ data, pageContext }) {
  const postDetail = data?.strapiNewsfeed;
  return (
    <Layout title={pageContext.metaName}>
      <Container width="1400px">
        <div style={{ minHeight: "100vh", position: "relative" }}>
          <PostWrapper>
            <Grid>
              <GridItem>
                <Heading as="h1" fontWeight={600} fontSize={24} mb="20px">
                  {postDetail.card.title}
                </Heading>
                <GatsbyImage
                  image={getImage(postDetail?.card?.image?.localFile)}
                  alt={postDetail?.card?.image?.alternativeText}
                  objectFit="contain"
                />
                <div className="content-data">
                  {parse(postDetail.contents?.data?.contents ?? "")}
                </div>
              </GridItem>
              <GridItem>
                <Heading as="h2" className="heading-right">
                  Get in Touch
                </Heading>
                <div
                  class="fb-page"
                  data-href="https://www.facebook.com/KAIO.AIVIETNAM"
                  data-tabs="timeline"
                  data-width="500"
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                >
                  <blockquote
                    cite="https://www.facebook.com/KAIO.AIVIETNAM"
                    class="fb-xfbml-parse-ignore"
                  >
                    <a href="https://www.facebook.com/KAIO.AIVIETNAM">
                      KAIO.AI Việt Nam
                    </a>
                  </blockquote>
                </div>
              </GridItem>
            </Grid>
          </PostWrapper>
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query PostDetail($slug: String) {
    strapiNewsfeed(card: { slug: { eq: $slug } }) {
      card {
        title
        timePublic(formatString: "MMMM DD,yyyy")
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                placeholder: BLURRED
                pngOptions: { compressionSpeed: 10, quality: 10 }
                layout: CONSTRAINED
              )
            }
          }
          alternativeText
        }
      }
      contents {
        data {
          contents
        }
      }
      author {
        authorName
        authorAvatar {
          url
          alternativeText
        }
      }
    }
  }
`;
